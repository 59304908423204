import React from "react";
import { ReactComponent as Fechar } from "../../../../assets/icons/modal/fechar.svg";
import cssF from "../../../modal/faleConnosco.module.css";

export default function MenuServicosBottom({
  onHide,
  infoJson,
  allinfoText,
  nodeRef,
}) {
  return (
    <>
      <div className={cssF.zone}></div>
      <section
        ref={nodeRef}
        className={` ${cssF.box} ${cssF.height} listagem_info `}
      >
        <div onClick={() => onHide()} className={` ${cssF.close} close`}>
          <Fechar />
        </div>
        <section className={cssF.retangle}>
          <ul className={``}></ul>
        </section>
      </section>
    </>
  );
}
