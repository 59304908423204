import React from "react";

import FormikEasyBuy from "./easybuy/FormikEasyBuy";

export default function SectionList({ infoJson, allinfo, allinfoText }) {
  return (
    <>
      {allinfo.length !== 0 && (
        <FormikEasyBuy
          allinfo={allinfo[0]}
          infoJson={infoJson}
          allinfoText={allinfoText}
        />
      )}
    </>
  );
}
