import React from "react";
import { theme } from "../../../tools/email/templates/Signup/theme";
import { WithDebug } from "../../../tools/email/templates/HtmlEmail";
import { A, Span } from "react-html-email";

const dataNow = new Intl.DateTimeFormat("pt", {
  dateStyle: "long",
  timeStyle: "medium",
}).format(new Date());

export default function EmailDestinatarioEasybuy({
  campos,
  infoJson,
  camposEmail,
  allinfo,
  data,
}) {
  return (
    <>
      <WithDebug
        theme={theme}
        title={camposEmail.assunto}
        emailHeaderHref={`http://${infoJson.Stand.Website}`}
        emailHeaderText={() => {
          return (
            <>
              <Span>
                <h1 style={theme.emailHeader.emailHeaderText}>NOVA MENSAGEM</h1>
                <A
                  href={`http://${infoJson.Stand.Website}`}
                  textDecoration={theme.emailHeader.a.textDecoration}
                  style={theme.emailHeader.a}
                >
                  em {`${infoJson.Stand.Website}`}
                </A>
              </Span>
            </>
          );
        }}
        emailHeaderLogo={allinfo.LogotipoEasybuy}
        bodyHeaderText={() => {
          return (
            <>
              <p style={theme.emailBody.bodyHeader.small}>
                {campos.nome} inseriu uma viatura:
                <br></br>
                <b>
                  {campos.marca.nome} {campos.modelo.nome} com a matrícula{" "}
                  {campos.matricula}
                </b>
                <br></br>
                Codigo Oferta:
                <b>{data.cod_viatura}</b>
              </p>
            </>
          );
        }}
        bodyContentComponent={() => {
          return (
            <>
              <p style={theme.emailBody.bodyFooter.span}>
                <b style={theme.emailHeader.color}>MENSAGEM ENVIADA</b> em{" "}
                {dataNow}
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                <b style={theme.emailHeader.color}>Dados da Viatura:</b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Nome :
                <b>
                  {campos.marca.nome} {campos.modelo.nome} {campos.versao.nome}
                </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Matricula : <b>{campos.matricula} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Ano :
                <b>
                  {campos.mes} / {campos.ano}
                </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Combustível : <b>{campos.combustivel.nome} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Potência : <b>{campos.potencia} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Cilindrada : <b>{campos.cilindrada} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Portas : <b>{campos.porta.nome} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Transmissão : <b>{campos.transmissao.nome} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Quilometros : <b>{campos.km} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Lugares : <b>{campos.lugar.nome} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                <b style={theme.emailHeader.color}>Histórico:</b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Primeiro dono :{" "}
                <b>{campos.historico.primeiroDono === 1 ? "Sim" : "Não"} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Veiculo Importado :{" "}
                <b>{campos.historico.importado === 1 ? "Sim" : "Não"} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Livro de Revisões :{" "}
                <b>{campos.historico.livroRevisao === 1 ? "Sim" : "Não"} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Acidentes Reportados :{" "}
                <b>{campos.historico.acidentes === 1 ? "Sim" : "Não"} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Veiculo de Fumador :{" "}
                <b>{campos.historico.fumador === 1 ? "Sim" : "Não"} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Tem segunda chave? :{" "}
                <b>{campos.historico.segundaChave === 1 ? "Sim" : "Não"} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                <b style={theme.emailHeader.color}>Estado dos Pneus:</b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Dianteiros :{" "}
                <b>
                  {campos.pneus.dianteiros === 1
                    ? "Bom"
                    : campos.pneus.dianteiros === 2
                    ? "Medio"
                    : campos.pneus.dianteiros === 3
                    ? "Mau"
                    : ""}
                </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Traseiros :{" "}
                <b>
                  {campos.pneus.traseiros === 1
                    ? "Bom"
                    : campos.pneus.traseiros === 2
                    ? "Medio"
                    : campos.pneus.traseiros === 3
                    ? "Mau"
                    : ""}
                </b>
              </p>
              {campos.ficheiro.map((ficheiro, index) => {
                const { url } = ficheiro;
                return (
                  <p key={index} style={theme.emailBody.bodyFooter.span}>
                    Ficheiro {index + 1}: <b>{url}</b>
                  </p>
                );
              })}
              <p style={theme.emailBody.bodyFooter.span}>
                <b style={theme.emailHeader.color}>Dados do proprietário:</b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Nome : <b>{campos.nome} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Email : <b>{campos.email} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Telemóvel : <b>{campos.telemovel} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Distrito : <b>{campos.distrito.nome} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Concelho : <b>{campos.concelho.nome} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Valor Pretendido : <b>{campos.valorPretende} </b>
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Motivo de Venda :{" "}
                <b>
                  {campos.motivoVenda === "1"
                    ? "Trocar por uma Viatura"
                    : "Venda da viatura"}{" "}
                </b>
              </p>
            </>
          );
        }}
        bodyFooterText={() => {
          return (
            <>
              <p style={theme.emailBody.bodyFooter.span}>
                <b>{campos.nome}</b>
              </p>
              <p style={theme.emailBody.bodyFooter.spanNoMargin}>
                Telef:<b>{campos.telemovel}</b>
              </p>
              <p style={theme.emailBody.bodyFooter.spanNoMarginA}>
                Email: <b>{campos.email}</b>
              </p>
            </>
          );
        }}
        debug={false}
      />
    </>
  );
}
