import React from "react";
import { Form, Col, Container, Row } from "react-bootstrap";
import { PickerOverlay } from "filestack-react";

import cssM from "../../../../modal/messageForm.module.css";
import { ReactComponent as Enviar } from "../../../../../assets/icons/modal/enviar.svg";
import * as apiDados from "../../../../../api/apiDados";
import { useState } from "react";
import css from "./formulario.module.css";
import PrimeiraParte from "./PrimeiraParte";
import SegundaParte from "./SegundaParte";
import { ReactComponent as Mais } from "../../../../../assets/icons/mais.svg";
import TerceiraParte from "./TerceiraParte";
import Spacer from "../../Spacer";
import cssH from "../../home.module.css";
import CountRequired from "./CountRequired";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const global = require("../../../../../config/config");

export default function FormEasyBuy({
  handleSubmit,
  touched,
  getFieldProps,
  setFieldValue,
  values,
  errors,
  infoJson,
  allinfoText,
}) {
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
  });
  const [segundaParte, setSegundaParte] = useState(false);
  const [Extras, setExtras] = useState([]);

  const [isPickerOverlayVisible, setIsPickerOverlayVisible] = useState(false);
  const [isPickerOverlayInsert, setIsPickerOverlayInsert] = useState(true);

  const handleInsertUpload = (result) => {
    result.map((item) => apiDados.InsertUpload(item.url, infoJson));
    setIsPickerOverlayInsert(false);
  };

  useEffect(() => {
    allinfoText.Formulario && setAlltexto(allinfoText.Formulario);
  }, [allinfoText]);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Container className={`${css.noPaddingxs} `}>
        <Col className={`${css.padding} listagem_info`}>
          <Spacer height="40px" clasName="col-12" />
          <Row>
            <h1 xs={12} className={cssH.listagem_titulo}>
              {alltexto.Titulo !== "" ? (
                alltexto.Titulo
              ) : (
                <> Compramos o seu carro</>
              )}
            </h1>

            <h2 xs={12} className={`${cssH.listagem_subtitulo} titulo-page`}>
              {alltexto.SubTitulo !== "" ? (
                alltexto.SubTitulo
              ) : (
                <> Avalie o seu carro online e receba uma proposta de compra</>
              )}
            </h2>

            <Form.Group className="mb-4 " controlId="titulo2">
              <Form.Label className={cssM.label}>
                Preencha os dados Iniciais da Viatura:
              </Form.Label>
            </Form.Group>
            <Form.Group controlId="titulo3" className="mb-4 ">
              <Form.Text id="passwordHelpBlock" muted>
                Os campos marcados com * são de preenchimento obrigatório.
              </Form.Text>
            </Form.Group>
            <Col>
              <PrimeiraParte
                touched={touched}
                getFieldProps={getFieldProps}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                infoJson={infoJson}
                setExtras={setExtras}
              />
            </Col>

            {segundaParte && (
              <>
                <Form.Group className="mb-4 " controlId="titulo2">
                  <Form.Label className={cssM.label}>
                    Preencha os restantes dados:
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="titulo3" className="mb-4 ">
                  <Form.Text id="passwordHelpBlock" muted>
                    Os campos marcados com * são de preenchimento obrigatório.
                  </Form.Text>
                </Form.Group>
                <SegundaParte
                  touched={touched}
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  infoJson={infoJson}
                  Extras={Extras}
                />
                <Col xs={12}>
                  <Col
                    xs={12}
                    className={`${css.boxShadowPares} boxShadowPares mb-3`}
                  >
                    <label>Fotos da viatura </label>
                    {isPickerOverlayInsert ? (
                      <div
                        onClick={() =>
                          isPickerOverlayVisible
                            ? setIsPickerOverlayVisible(false)
                            : setIsPickerOverlayVisible(true)
                        }
                        className={`btnPesquisa ${css.btnMore}`}
                      >
                        Preencher Fotos da viatura
                        <Mais
                          style={{ marginLeft: "10px", stroke: "#ffffff" }}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() =>
                          isPickerOverlayVisible
                            ? setIsPickerOverlayVisible(false)
                            : setIsPickerOverlayVisible(true)
                        }
                        className={`btnPesquisa ${css.btnMore}`}
                      >
                        Fotos inseridas com sucesso
                        <Mais
                          style={{ marginLeft: "10px", stroke: "#ffffff" }}
                        />
                      </div>
                    )}
                  </Col>
                </Col>
                {isPickerOverlayVisible && (
                  <PickerOverlay
                    apikey={process.env.REACT_APP_FILESTACK_APIKEY}
                    onUploadDone={(res) => {
                      setFieldValue("ficheiro", res.filesUploaded);
                      handleInsertUpload(res.filesUploaded);
                    }}
                    pickerOptions={{
                      onClose: () => {
                        setIsPickerOverlayVisible(false);
                      },
                      ...global.PickerOptions,
                    }}
                  />
                )}
              </>
            )}
          </Row>
          <Spacer height="50px" clasName="col-12" />
        </Col>

        {segundaParte && (
          <>
            <Spacer height="30px" clasName="col-12" />
            <Col className={`${css.padding} listagem_info`}>
              <Spacer height="40px" clasName="col-12" />
              <Row>
                <Form.Group className="mb-4 " controlId="titulo2">
                  <Form.Label className={cssM.label}>
                    Preencha os dados do proprietário:
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="titulo3" className="mb-4 ">
                  <Form.Text id="passwordHelpBlock" muted>
                    Os campos marcados com * são de preenchimento obrigatório.
                  </Form.Text>
                </Form.Group>
                <Col>
                  <TerceiraParte
                    touched={touched}
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    infoJson={infoJson}
                  />
                </Col>

                <Form.Group controlId="FormEasybuy" className="mb-4  ">
                  <Form.Label style={{ display: "flex" }}>
                    <Form.Check
                      type="checkbox"
                      label={
                        <>
                          Li e Aceito
                          <a
                            className={` `}
                            style={{ marginLeft: "10px" }}
                            href="/privacidade/"
                            target="_blank"
                          >
                            os Termos de Utilização e Proteção de Dados
                          </a>
                        </>
                      }
                      {...getFieldProps("terms")}
                      isInvalid={!!errors.terms && touched.terms}
                    />
                  </Form.Label>
                </Form.Group>
                <Col>
                  <button
                    disabled={!values.terms}
                    className={` btnForm ${cssM.btn} `}
                    type="submit"
                  >
                    <Enviar fill={"#ffffff"} style={{ marginRight: "10px" }} />
                    Enviar
                  </button>
                </Col>
              </Row>
              <Spacer height="40px" clasName="col-12" />
            </Col>
          </>
        )}
      </Container>
      <CountRequired
        values={values}
        segundaParte={segundaParte}
        setSegundaParte={setSegundaParte}
      />

      {alltexto.Zona !== "" && (
        <>
          <Spacer height="40px" clasName="col-12" />
          <Col>{alltexto.Zona}</Col>
        </>
      )}
    </Form>
  );
}
